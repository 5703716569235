var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-6 sm:grid-cols-1 sm:gap-1" },
      [
        _vm._m(1),
        _c("div", { staticClass: "help-sumally" }, [
          _c(
            "div",
            { staticClass: "help-index border-2 border-base-yellow mb-8 p-4" },
            [
              _c(
                "h4",
                { staticClass: "font-bold text-xl text-left mt-4 mb-2 ml-4" },
                [_vm._v("他のヘルプを見る")]
              ),
              _c(
                "div",
                { staticClass: "p-4" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/" },
                    },
                    [_vm._v(" 主な流れ ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/account" },
                    },
                    [_vm._v(" 新規アカウント登録 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-register" },
                    },
                    [_vm._v(" 作品登録(単体登録) ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-register-all" },
                    },
                    [_vm._v(" 作品登録(一括登録) ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/product-list" },
                    },
                    [_vm._v(" 映画一覧の確認と編集 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/quotation" },
                    },
                    [_vm._v(" 見積書作成 ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/upload" },
                    },
                    [_vm._v(" 納品アップロード ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/invoice" },
                    },
                    [_vm._v(" 請求書について ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/youtube-limit" },
                    },
                    [_vm._v(" Youtubeでの限定公開の方法がわかりません ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/vimeo-register" },
                    },
                    [_vm._v(" Vimeoで動画登録を行いたいです ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/vimeo-limit" },
                    },
                    [_vm._v(" Vimeoでの限定公開の方法がわかりません ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/password" },
                    },
                    [_vm._v(" アカウントのパスワードを忘れました ")]
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass:
                        "inline-block bg-light-grey rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-3 mb-3",
                      attrs: { to: "/help/ch/email" },
                    },
                    [_vm._v(" 新規アカウント登録完了メールが届きません ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "text-center font-bold text-3xl" }, [
          _vm._v("Vimeoでの限定公開の方法がわかりません"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-9 flex flex-col justify-center sm:col-span-1" },
      [
        _c(
          "div",
          {
            staticClass: "w-full rounded overflow-hidden shadow-lg mb-6",
            attrs: { id: "help-ch-14" },
          },
          [
            _c("div", { staticClass: "px-6 pb-12" }, [
              _c("ul", { staticClass: "text-base" }, [
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("1.")]
                    ),
                    _vm._v(
                      " Vimeo上での公開を避けたい場合は、Vimeoをアップグレードすることで、"
                    ),
                    _c("br"),
                    _vm._v(
                      " Vimeo上で動画の公開を行わず、filminaitonのサイト上に動画を埋め込む、"
                    ),
                    _c("br"),
                    _vm._v("限定公開が可能となります。 "),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/14-01.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("2.")]
                    ),
                    _vm._v(
                      " まずはVimeoのサイト(https://vimeo.com/jp/upgrade)にて"
                    ),
                    _c("br"),
                    _vm._v(" アカウントをアップグレードください。"),
                    _c("br"),
                    _vm._v(
                      " 「Plus」プランにて限定公開の設定が可能です。(※2021年2月地点) "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/14-02.jpg"),
                      alt: "",
                    },
                  }),
                ]),
                _c("li", { staticClass: "mb-4" }, [
                  _c("p", { staticClass: "flex flex-row" }, [
                    _c(
                      "span",
                      { staticClass: "text-5xl mx-3 text-yellow-400" },
                      [_vm._v("3.")]
                    ),
                    _vm._v(
                      " アップグレード後、設定ページにて、プライバシー項目を編集します。"
                    ),
                    _c("br"),
                    _vm._v(
                      " プライバシー項目内の「誰が視聴することができますか？」にて、"
                    ),
                    _c("br"),
                    _vm._v(
                      " 「この動画をVimeo.comで非表示にする」を選択ください。"
                    ),
                    _c("br"),
                    _vm._v(" 選択後、「保存」ボタンを押してください。"),
                    _c("br"),
                    _vm._v(" これで、Vimeoでの「限定公開」設定は完了です。"),
                    _c("br"),
                    _vm._v(" ※プライバシー項目の編集方法については、"),
                    _c("br"),
                    _vm._v(
                      "Vimeoで動画登録を行いたいです。(動画のプライバシー設定)をご参照ください。 "
                    ),
                  ]),
                  _c("img", {
                    staticClass:
                      "border border-gray-400 shadow-md my-2 w-6/12 mx-auto",
                    attrs: {
                      src: require("@/assets/image/help/ch/14-03.jpg"),
                      alt: "",
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }